* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-header-area {
  position: absolute;
  width: 100%;
  z-index: 9;
}

.navbar-style-two-with-color {
  background-color: #FFFAF2 !important
}

.main-responsive-nav {
  display: none;
}

.avatar-creator {
  clip-path: polygon(25% 7%, 77% 7%, 100% 50%, 75% 91%, 25% 91%, 0% 50%);
  display: flex;
  justify-content:cenetr;
  align-items: cenetr;
}
.wrapper-avatar {
  clip-path: polygon(24% 4%, 80% 4%, 100% 48%, 80% 94%, 24% 94%, 0 50%);
  display: flex;
  justify-content:cenetr;
  align-items: cenetr;
}

#bui-2 {
  z-index: 999
}



